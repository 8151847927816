<template>
  <div class="orderList-view view pa24">
    <el-row :gutter="24">
      <el-col :xl="24" :lg="24">
        <div class="flex-a-c pt10">
          <div>
            优惠券状态：
            <el-radio style="margin-right: 0;" v-model="couponStateValue" :label="item.label"
              v-for="(item, index) in orderStatus" :key="index" border>
              {{ item.text }}
            </el-radio>
          </div>
          <div>
            <el-button type="primary" class="ml10" @click="searchFun">搜索</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- :spanMethod="objectSpanMethod" -->
    <commonTable :tableData="tableData" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage" :loading="loading" :total="total">

      <template v-slot:table>
        <el-table-column type="index" align="center" label="序号" />
        <el-table-column prop="nickName" align="center" label="领取用户" />
        <el-table-column prop="name" align="center" label="优惠券名称" />
        <el-table-column prop="grantModeText" align="center" label="发放方式" />
        <el-table-column align="center" label="优惠券折扣/金额">
          <template slot-scope="scope">
            <span v-if="scope.row.couponType == 1">{{ scope.row.couponFaceValue }}元</span>
            <span v-if="scope.row.couponType == 2">{{ scope.row.couponDiscount }}折</span>
          </template>
        </el-table-column>
        <el-table-column prop="minAmount" align="center" label="最低消费金额" />
        <el-table-column align="center" width="200" label="发放日期">
          <template slot-scope="scope">
            <span>{{ scope.row.grantStartTime }}到{{ scope.row.grantEndTime }}</span>
          </template>
        </el-table-column>
        <el-table-column width="200" align="center" label="有效日期">
          <template slot-scope="scope">
            <span>{{ scope.row.effectiveStartTime }}到{{ scope.row.effectiveEndTime }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="receiveTime" align="center" label="领取时间" />
        <el-table-column align="center" prop="couponStateText" label="状态" >
          <template slot-scope="scope">
            <el-tag>{{ scope.row.couponStateText }}</el-tag>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
  coupponRecords
} from "@/api/coupon";

export default {
  name: "orderList",
  components: {
    commonTable,
  },
  data() {
    return {
      couponStateValue: 0, //订单状态
      spanArr: [], //二维数组，用于存放单元格合并规则
      position: 0, //用于存储相同项的开始index
      loading: '',
      total: 0, //总条数
      currentPage: 1, //当前页数
      couponId: '',// 优惠券id
      start: '',
      end: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      pageNum: 1,
      pageSize: 10,
      orderStatus: [
        //订单状态
        { text: "全部", label: 0 },
        { text: "待使用", label: 1 },
        { text: "已使用", label: 2 },
        { text: "已过期", label: 3 },
      ],
      tableData: [],
    };
  },
  created() {
    this.couponId = this.$route.query.id
    this.getCouponList()

  },
  watch: {
    couponStateValue(val) {
      //-console.log(val)
    }
  },

  methods: {
    searchFun() {
      this.pageNum = 1
      this.getCouponList();
    },
    async getCouponList() {
      this.loading = true
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        couponId: this.couponId,
        couponState: this.couponStateValue
      };
      const result = await coupponRecords(data)
      this.loading = false
      try {
        this.tableData = result.data.list;
        this.total = result.data.total
        this.tableData.forEach(v => {
          v.orderPrice = (v.orderPrice / 100).toFixed(2)
        })
      } catch (err) {
        this.tableData = []
      }
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getCouponList();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.getCouponList();
    },

  },
};
</script>

<style scoped lang='scss'>
.orderList-view {
  padding-top: 14px;
}
</style>